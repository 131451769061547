<template>
  <div class="layout-main">
    <div class="layout-table-basic">
      <div class="toolbar">
        <div class="search">
          <el-form :inline="true">
            <el-form-item label="关键字 ：">
              <el-input size="small" placeholder="请输入品牌车型/车辆所在地/车牌号" v-model="filterData.keyword" clearable></el-input>
            </el-form-item>
            <el-form-item label="车辆类型 ：">
              <el-select v-model="filterData.carType" style="width:90px">
                <el-option v-for="item in carTypeOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="金融公司名称 ：">
              <el-select v-model="filterData.salesId">
                <el-option v-for="item in salesOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="handleSearchReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="handle">
          <el-button size="small" type="primary" @click="handleUploadBatchReport('uploadBatchForm')">一键上传</el-button>
        </div>
        <div class="handle">
          <el-button size="small" type="primary" @click="handleUploadBatchDelete('uploadBatchForm')">一键删除</el-button>
        </div>
        <div class="handle">
          <el-button size="small" type="primary" @click="handleCreate('form')">添加</el-button>
        </div>
      </div>

      <div class="table">
        <el-table v-loading="isTableloading" :data="tableData" stripe highlight-current-row style="width: 100%" height="100%">
          <el-table-column prop="" label="" width="10"> </el-table-column>
          <el-table-column prop="id" label="ID" width="60"> </el-table-column>
          <el-table-column prop="carCode" label="车辆图片" width="160">
            <template slot-scope="scope">
              <!-- <el-carousel height="100px" indicator-position="none">
                <el-carousel-item v-for="itme in scope.row.carImagesList" :key="itme.id"> -->
                  <img style="width:160px;height:100px;" :src="scope.row.firstImagesUrl" />
                <!-- </el-carousel-item>
              </el-carousel> -->
            </template>
          </el-table-column>
          <!-- <el-table-column prop="salesId" label="金融公司ID" width="100"> </el-table-column> -->
          <el-table-column prop="salesTitle" label="金融公司名称" width="120"> </el-table-column>
          <el-table-column prop="carCode" label="车辆编号" width="100"> </el-table-column>
          <el-table-column prop="carLicenceDisc" label="行驶证" width="70"> </el-table-column>
          <el-table-column prop="carTitle" label="品牌车型" width="120"> </el-table-column>
          <el-table-column prop="" label="" width="20"> </el-table-column>
          <el-table-column prop="carType" label="类型" width="65">
            <template slot-scope="scope">
              <div v-if="scope.row.carType == 1">过户车</div>
              <div v-else>债权车</div>
            </template>
          </el-table-column>
          <el-table-column prop="carProperties" label="性质" width="60"> </el-table-column>
          <el-table-column prop="carCity" label="车辆所在地" width="100"></el-table-column>
          <el-table-column prop="carLicensePlate" label="车牌号" width="95"> </el-table-column>
          
          <el-table-column prop="carVinCode" label="发动机号" width="160"></el-table-column>
          <el-table-column prop="carIskey" label="有无钥匙" width="100"> </el-table-column>
          <el-table-column prop="carFrameCode" label="车架号" width="220"> </el-table-column>
          <el-table-column prop="carLicenseDate" label="上牌日期" width="120"> </el-table-column>
          <el-table-column prop="carMileage" label="表显里程" width="100"> </el-table-column>
          <el-table-column prop="carColor" label="车辆颜色" width="100"> </el-table-column>
          <el-table-column prop="carAssessmentReport" label="评估报告" width="100"> </el-table-column>
          <el-table-column prop="carRegisterCertificate" label="车辆登记证书" width="100"> </el-table-column>
          <el-table-column prop="carSettleCertificate" label="结清证明" width="100"> </el-table-column>
          <el-table-column prop="carReleaseMortgage" label="解除抵押证明" width="100"> </el-table-column>
          <el-table-column prop="carReleaseAttorney" label="解押委托书" width="100"> </el-table-column>
          <el-table-column prop="carLicenseCertificate" label="营业执照" width="100"> </el-table-column>
          <el-table-column prop="carMortgageReport" label="车辆抵押登记申请表" width="100"> </el-table-column>
          <el-table-column prop="carRemark" label="备注信息" width="100"> </el-table-column>
          <el-table-column prop="carViolation" label="违章信息" width="100" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column label="操作" fixed="right" width="260">
            <template slot-scope="scope">
              <el-button @click.native.stop="handleEdit(scope.row, 'form')" type="text">编辑</el-button>
              |
              <el-button @click.native.stop="handleUploadImage(scope.row)" type="text">上传图片</el-button>
              |
              <el-button @click.native.stop="handleUploadReport(scope.row)" type="text">上传评估报告</el-button>
              |
              <el-popconfirm cancel-button-type="Primary" icon="el-icon-info" icon-color="red" :title="'您确定要删除【' + scope.row.carCode + '】记录吗？'" @confirm="handleDeleteConfirm(scope.row)" placement="bottom-end">
                <el-button type="text" slot="reference" @click.native.stop="" style="color:red">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination :page="searchParams.page" :page-size="searchParams.limit" :total="total" background layout="total, sizes, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
      </div>

      <!-- 添加、编辑，模态框组件 -->
      <el-dialog :title="dialogTitle" :visible="dialogVisible" @close="dialogVisible = false" width="600px">
        <el-form :model="form" ref="form" label-width="120px">
          <el-form-item label="金融公司名称">
            <el-select v-model="form.salesId">
              <el-option v-for="item in salesOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆编号" prop="carCode">
            <el-input v-model="form.carCode" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="品牌车型" prop="carTitle">
            <el-input v-model="form.carTitle" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="车辆性质" prop="carProperties">
            <el-input v-model="form.carProperties" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="有无行驶证" prop="carLicenceDisc">
            <el-input v-model="form.carLicenceDisc" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="车辆所在地" prop="carCity">
            <el-input v-model="form.carCity" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="发动机号" prop="carVinCode">
            <el-input v-model="form.carVinCode" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="车牌号" prop="carLicensePlate">
            <el-input v-model="form.carLicensePlate" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="有无钥匙" prop="carIskey">
            <el-input v-model="form.carIskey" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="车架号" prop="carFrameCode">
            <el-input v-model="form.carFrameCode" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="上牌时间" prop="carLicenseDate">
            <el-input v-model="form.carLicenseDate"  placeholder="请输入"> </el-input>
          </el-form-item>
          <el-form-item label="车辆颜色" prop="carColor">
            <el-input v-model="form.carColor" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="表显里程" prop="carMileage">
            <el-input v-model="form.carMileage" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="评估报告" prop="carAssessmentReport">
            <el-input v-model="form.carAssessmentReport" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="车辆登记证书" prop="carRegisterCertificate">
            <el-input v-model="form.carRegisterCertificate" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="结清证明" prop="carSettleCertificate">
            <el-input v-model="form.carSettleCertificate" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="解除抵押证明" prop="carReleaseMortgage">
            <el-input v-model="form.carReleaseMortgage" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="解押委托书" prop="carReleaseAttorney">
            <el-input v-model="form.carReleaseAttorney" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="营业执照" prop="carLicenseCertificate">
            <el-input v-model="form.carLicenseCertificate" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="车辆抵押登记申请表" prop="carMortgageReport">
            <el-input v-model="form.carMortgageReport" placeholder="请输入"></el-input>
          </el-form-item>
           <el-form-item label="停车费" prop="carParkingRate">
            <el-input v-model="form.carParkingRate" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="车辆入库日期" prop="carWarehousingDate">
            <el-input v-model="form.carWarehousingDate" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="备注信息" prop="carRemark">
            <el-input v-model="form.carRemark" placeholder="请输入" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="违章信息" prop="carViolation">
            <el-input v-model="form.carViolation" placeholder="请输入" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleSubmit()">保存</el-button>
        </div>
      </el-dialog>

      <!--  -->
      <el-dialog :title="titleForImage" :visible="dialogUploadVisible" @close="dialogUploadVisible = false" width="1220px" v-if="dialogUploadVisible">
        <el-form :model="form" ref="form" label-width="0px" >
          <!-- 使用element-ui自带样式 --> <!-- 预览 --><!-- 删除 -->
          <el-form-item label="">
     <div style="display: flex;height: 430px;">
    
      <ul class="el-upload-list el-upload-list--picture-card">
          <draggable v-model="fileList">
              <li v-for="(item, index) in fileList" :key="index" class="el-upload-list__item is-success animated">
                  <img :src="item.url" alt="" class="el-upload-list__item-thumbnail ">
                  <i class="el-icon-close"></i>
                  <span class="el-upload-list__item-actions">
                   
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreviewFileDetail(item)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    
                    <span class="el-upload-list__item-delete" @click="handleRemove(item,fileList)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
              </li>
          </draggable>
          <el-upload ref="dragTable" class="upload-demo" 
            action="/api/files/images/upload" name="multipartFiles" :multiple="true" 
            :on-preview="handlePreview" :on-remove="handleRemove" 
            :before-upload="beforeAvatarUpload" :on-success="handleUploadSuccess" 
            :file-list="fileList"
            :show-file-list="false" 
            list-type="picture-card"
            >
          <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
      </el-upload>
      </ul>
  </div>  
          </el-form-item>
 <!-- <el-form-item  label=""  prop="carCode" >
             <el-upload ref="dragTable" class="upload-demo" 
            action="/api/files/images/upload" name="multipartFiles" :multiple="true" 
            :on-preview="handlePreview" :on-remove="handleRemove" 
            :before-upload="beforeAvatarUpload" :on-success="handleUploadSuccess" 
            :file-list="fileList"
            list-type="picture-card"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
            </el-upload>
            </el-form-item> -->
      
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogUploadVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleUploadSubmit()">保存</el-button>
        </div>
      </el-dialog>

<!-- 预览弹出层 -->
  <el-dialog append-to-body :visible.sync="dialogVisibleDetail" width="800px">
      <img height="100%" width="100%" :src="dialogImageDetailUrl" alt="">
  </el-dialog>
      

      <!--  -->
      <el-dialog :title="titleForReport" :visible="dialogUploadReportVisible" @close="dialogUploadReportVisible = false" width="640px" v-if="dialogUploadReportVisible">
        <el-form :model="form" ref="form" label-width="120px">
          <el-form-item label="报告二维码" prop="carEvalReportType">
            <!-- <el-select v-model="form.carEvalReportType">
              <el-option v-for="item in reportTypeOptions" :key="item.value" 
              :value="item.value" :label="item.label"></el-option>
            </el-select> -->
            <el-upload class="avatar-uploader" action="/api/files/images/upload" name="multipartFiles" 
            :show-file-list="false" :on-success="handleReportSuccess" :before-upload="beforeReportUpload">
              <img v-if="form.carEvalReportContent!='无'" :src="form.carEvalReportContent" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <!-- <el-form-item label="地址" prop="carEvalReportContent" v-if="form.carEvalReportType == 1">
            <el-input v-model="form.carEvalReportContent" placeholder="请输入"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="" prop="carEvalReportContent" v-if="form.carEvalReportType == 2">
            <el-upload class="avatar-uploader" action="/api/files/images/upload" name="multipartFiles" 
            :show-file-list="false" :on-success="handleReportSuccess" :before-upload="beforeReportUpload">
              <img v-if="form.carEvalReportContent" :src="form.carEvalReportContent" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>          -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogUploadReportVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleUploadReportSubmit()">保存</el-button>
        </div>
      </el-dialog>

       <el-dialog :title="titleForReport" :visible="dialogUploadBatchReportVisible" 
       @close="dialogUploadBatchReportVisible = false" width="640px" v-if="dialogUploadBatchReportVisible">
        <el-form :model="uploadBatchForm" ref="uploadBatchForm" label-width="120px">
           <el-form-item label="开始ID" prop="startId">
            <el-input v-model="uploadBatchForm.startId" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="结束ID" prop="endId">
            <el-input v-model="uploadBatchForm.endId" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="报告二维码" prop="imageUrl">
            <!-- <el-select v-model="form.carEvalReportType">
              <el-option v-for="item in reportTypeOptions" :key="item.value" 
              :value="item.value" :label="item.label"></el-option>
            </el-select> -->
            <el-upload class="avatar-uploader" action="/api/files/images/upload" name="multipartFiles" 
            :show-file-list="false" :on-success="handleReportBatchSuccess" :before-upload="beforeReportUpload">
              <img v-if="uploadBatchForm.imageUrl!=''" :src="uploadBatchForm.imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
         
          <!-- <el-form-item label="" prop="carEvalReportContent" v-if="form.carEvalReportType == 2">
            <el-upload class="avatar-uploader" action="/api/files/images/upload" name="multipartFiles" 
            :show-file-list="false" :on-success="handleReportSuccess" :before-upload="beforeReportUpload">
              <img v-if="form.carEvalReportContent" :src="form.carEvalReportContent" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>          -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogUploadBatchReportVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleUploadBatchReportSubmit()">保存</el-button>
        </div>
      </el-dialog>

      <el-dialog :title="titleForReport" :visible="dialogBatchReportDeleteVisible" 
       @close="dialogBatchReportDeleteVisible = false" width="640px" v-if="dialogBatchReportDeleteVisible">
        <el-form :model="deleteBatchForm" ref="deleteBatchForm" label-width="120px">
           <el-form-item label="开始ID" prop="startId">
            <el-input v-model="deleteBatchForm.startId" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="结束ID" prop="endId">
            <el-input v-model="deleteBatchForm.endId" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogBatchReportDeleteVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleUploadBatchReportDeleteSubmit()">保存</el-button>
        </div>
      </el-dialog>

      <!-- 列表详情，抽屉组件 -->
      <el-drawer title="详情" :visible.sync="drawerDetailVisible">
        <div class="layout-drawer-handle">
          <el-button type="primary" size="small" @click="handleEdit(drawerDetailObj, 'form')">编辑</el-button>
          <el-popconfirm cancel-button-type="Primary" icon="el-icon-info" icon-color="red" :title="'您确定要删除【' + drawerDetailObj.carCode + '】记录吗？'" @confirm="handleDeleteConfirm(drawerDetailObj)" placement="bottom-end">
            <el-button type="danger" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </div>
        <div class="layout-drawer-detail">
          <div class="layout-drawer-detail-top">
            <div class="layout-drawer-detail-top-title">
              基础信息
            </div>
          </div>
          <div class="layout-drawer-detail-main">
            <div class="layout-drawer-detail-list">
              <DrawerDetail liLabel="车辆编号" :liValue="drawerDetailObj.carCode"></DrawerDetail>
              <DrawerDetail liLabel="品牌车型" :liValue="drawerDetailObj.carTitle"></DrawerDetail>
              <DrawerDetail liLabel="车辆性质" :liValue="drawerDetailObj.carProperties"></DrawerDetail>
              <DrawerDetail liLabel="有无行驶证" :liValue="drawerDetailObj.carLicenceDisc"></DrawerDetail>
              <DrawerDetail liLabel="车辆所在地" :liValue="drawerDetailObj.carCity"></DrawerDetail>
              <DrawerDetail liLabel="发动机号" :liValue="drawerDetailObj.carVinCode"></DrawerDetail>
              <DrawerDetail liLabel="车牌号" :liValue="drawerDetailObj.carLicensePlate"></DrawerDetail>
              <DrawerDetail liLabel="有无钥匙" :liValue="drawerDetailObj.carIskey"></DrawerDetail>
              <DrawerDetail liLabel="车架号" :liValue="drawerDetailObj.carFrameCode"></DrawerDetail>
              <DrawerDetail liLabel="上牌时间" :liValue="drawerDetailObj.carLicenseDate"></DrawerDetail>
              <DrawerDetail liLabel="车辆颜色" :liValue="drawerDetailObj.carColor"></DrawerDetail>
              <DrawerDetail liLabel="表显里程" :liValue="drawerDetailObj.carMileage"></DrawerDetail>
              <DrawerDetail liLabel="评估报告" :liValue="drawerDetailObj.carAssessmentReport"></DrawerDetail>
              <DrawerDetail liLabel="车辆登记证书" :liValue="drawerDetailObj.carRegisterCertificate"></DrawerDetail>
              <DrawerDetail liLabel="结清证明" :liValue="drawerDetailObj.carSettleCertificate"></DrawerDetail>
              <DrawerDetail liLabel="解除抵押证明" :liValue="drawerDetailObj.carReleaseMortgage"></DrawerDetail>
              <DrawerDetail liLabel="解押委托书" :liValue="drawerDetailObj.carReleaseAttorney"></DrawerDetail>
              <DrawerDetail liLabel="营业执照" :liValue="drawerDetailObj.carLicenseCertificate"></DrawerDetail>
              <DrawerDetail liLabel="车辆抵押登记申请表" :liValue="drawerDetailObj.carMortgageReport"></DrawerDetail>
              <DrawerDetail liLabel="停车费" :liValue="drawerDetailObj.carParkingRate"></DrawerDetail>
              <DrawerDetail liLabel="车辆入库日期" :liValue="drawerDetailObj.carWarehousingDate"></DrawerDetail>
              <DrawerDetail liLabel="备注信息" :liValue="drawerDetailObj.carRemark"></DrawerDetail>
              <DrawerDetail liLabel="违章信息" :liValue="drawerDetailObj.carViolation"></DrawerDetail>
              
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Request from '@/api/car';
import RequestSales from '@/api/sale';
import draggable from 'vuedraggable';

import DrawerDetail from '../../../layout/console/components/DrawerDetail.vue';
export default {
  components: {
    DrawerDetail,
    draggable
  },

  data() {
    return {
      dialogVisibleDetail:false,
      dialogImageDetailUrl:'',
      //列表相关
      tableData: null,
      isTableloading: false,
      total: 0,

      //查询区域字段
      filterData: {
        keyword: '',
        carType: '',
        salesId: '',
      },

       uploadBatchForm: {
        startId: '',
        endId: '',
        imageUrl: '',
      },

      deleteBatchForm: {
        startId: '',
        endId: ''
      },
 
      titleForImage: '',

      salesOptions: [],

      carTypeOptions: [
        { label: '过户车', value: 1 },
        { label: '债权车', value: 2 },
      ],

      shifouOptions: [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ],

      youwuOptions: [
        { label: '有', value: 1 },
        { label: '无', value: 0 },
      ],

      reportTypeOptions:[
        { label: '链接', value: 1 },
        { label: '二维码图片', value: 2 },
      ],

      //发送查询请求字段
      searchParams: {
        limit: 10,
        page: 1,
      },

      //模态框
      isEdit: false,
      idEdit: '',
      dialogVisible: false,
      dialogTitle: '添加',

      //图片模态框
      dialogUploadVisible: false,
      fileList: [],

      drawerDetailVisible: false,
      drawerDetailObj: {},

      form: {
        salesId: '',
        carCode: '',
        carTitle: '',
        carProperties: '',
        carIstransfer: '',
        carLicenceDisc: '',
        // carProvince: '',
        carCity: '',
        // carArea: '',
        // carAddress: '',
        carVinCode: '',
        carLicensePlate: '',
        carIskey: '',
        carFrameCode: '',
        carLicenseDate: '',
        carColor: '',
        carIsstart: '',
        carMileage: '',
        carAssessmentReport: '',
        carRegisterCertificate: '',
        carSettleCertificate: '',
        carReleaseMortgage: '',
        carReleaseAttorney: '',
        carLicenseCertificate: '',
        carMortgageReport: '',
        carRemark: '',
        carViolation: '',
        carEvalReportType:2,
        carEvalReportContent:'',
        carParkingRate:'',
        carWarehousingDate:'',
      },

      //

      titleForReport: '',
      dialogUploadReportVisible: false,
      dialogUploadBatchReportVisible: false,
      dialogBatchReportDeleteVisible: false
    };
  },

  mounted() {
    RequestSales.listAll().then((res) => {
      if (res && res.code === 200) {
        res.data.forEach((e) => {
          this.salesOptions.push({
            label: e.salesTitle,
            value: e.id,
          });
        });

        if (this.$route.query.id) {
          this.filterData.salesId = this.$route.query.id * 1;
          this.searchParams.salesId = this.$route.query.id * 1;
        }

        this.getTableList();
      }
    });
  },

  methods: {
    // 放大
handlePictureCardPreviewFileDetail(file) {

    this.dialogImageDetailUrl = file.imageUrl;
    this.dialogVisibleDetail = true;
},
    onEnd() {
         console.log('最新的排序顺序是：',this.homePosterList)
      },
    
    // onDragOver(event) {
    //   event.preventDefault()
    //   const { src } = event.target
    //   this.targetSrc = src
    // },
    // onDragEnd(idx) {
    //   const targetIdx = this.imgList.indexOf(this.targetSrc);
    //   [this.imgList[idx], this.imgList[targetIdx]] = [this.imgList[targetIdx], this.imgList[idx]]
    //   this.imgList = [...this.imgList]
    // },
    getTableList() {
      this.isTableloading = true;
      Request.page(this.searchParams).then((res) => {
        this.isTableloading = false;
        if (res && res.code === 200) {
          if (res.data.list.length > 0) {
            this.renderTable(res.data.list);
          } else {
            if (this.searchParams.page > 1) {
              this.searchParams.page--;
              this.getTableList();
            } else {
              this.renderTable(res.data.list);
            }
          }
          this.total = res.data.total;
        }
      });
    },

    // 渲染表格
    renderTable(data) {
      this.tableData = [];
      data.forEach((e) => {
        this.tableData.push(e);
      });
    },

    //查询搜索按钮
    handleSearch() {
      for (let key in this.filterData) {
        this.searchParams[key] = this.filterData[key];
      }

      for (let key in this.searchParams) {
        if (!this.searchParams[key]) {
          delete this.searchParams[key];
        }
      }

      this.searchParams.page = 1;
      this.getTableList();
    },

    //查询重置按钮
    handleSearchReset() {
      for (let key in this.filterData) {
        this.filterData[key] = '';
      }

      this.handleSearch();
    },

    //分页改变每页显示数量
    handleSizeChange(val) {
      this.searchParams.page = 1;
      this.searchParams.limit = val;
      this.getTableList();
    },

    //分页跳转至当前页
    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getTableList();
    },

    //添加按钮
    handleCreate(formName) {
      this.isEdit = false;
      this.dialogTitle = '添加';
      this.dialogVisible = true;

      this.$nextTick(() => {
        this.$refs[formName].resetFields();

        for (let key in this.form) {
          this.form[key] = '';
        }
      });
    },

    //列表里操作列编辑按钮
    handleEdit(row, formName) {
      this.isEdit = true;
      this.idEdit = row.id;
      this.dialogTitle = '编辑';
      this.dialogVisible = true;

      this.$nextTick(() => {
        this.$refs[formName].resetFields();

        for (let key in this.form) {
          this.form[key] = row[key];
        }
      });
    },

    //添加、编辑模态框的保存提交按钮
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.form);

          if (!this.isEdit) {
            Request.create(data).then((res) => {
              if (res && res.code === 200) {
                this.msgSuccess('添加成功');
                this.searchParams.page = 1;
                this.getTableList();
              } else {
                this.msgError('添加失败：' + res.msg);
              }
              this.dialogVisible = false;
            });
          } else {
            data.id = this.idEdit;
            Request.update(data).then((res) => {
              if (res && res.code === 200) {
                this.msgSuccess('编辑成功');
                this.getTableList();

                if (this.drawerDetailVisible) this.drawerDetailObj = Object.assign(this.drawerDetailObj, data);
              } else {
                this.msgError('编辑失败：' + res.msg);
              }
              this.dialogVisible = false;
            });
          }
        } else {
          return false;
        }
      });
    },

    //列表里操作列删除按钮弹出提示框的 确定 按钮
    handleDeleteConfirm(row) {
      let loading = this.$loading({ background: 'rgba(0, 0, 0, 0.6)' });

      Request.remove(row.id).then((res) => {
        if (res && res.code == 200) {
          this.msgSuccess('删除成功');
          loading.close();
          this.getTableList();

          if (this.drawerDetailVisible) this.drawerDetailVisible = false;
        }
      });
    },

    //点击列表row右侧弹出详情框
    handleDrawerDetailVisible(row) {
      this.drawerDetailObj = row;
      this.drawerDetailVisible = true;
    },

    //
    handleUploadImage(row) {
      this.titleForImage = '上传图片 车牌号：' + row.carLicensePlate;
      this.idEdit = row.id;

      this.fileList.length = 0;
      row.carImagesList.forEach((e) => {
        let obj = Object.assign({}, e, {
          name: e.imageName,
          url: e.imageUrl,
        });
        this.fileList.push(obj);
      });
      this.dialogUploadVisible = true;
    },

    handleRemove(file, fileList) {
      let index = this.fileList.findIndex((e) => {
        return e.uid === file.uid;
      });
      if (index != -1) {
        this.fileList.splice(index, 1);
      }

      // console.log(index)
      // // this.fileList.length = 0;
      // // fileList.forEach((e) => {
      // //   let obj = Object.assign({}, e, {
      // //     name: e.imageName,
      // //     url: e.imageUrl,
      // //   });
      // //   this.fileList.push(obj);
      // // });
    },
    handlePreview(file) {
      window.open(file.url);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';

      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG 或者 PNG 格式!');
      }
      return isJPG || isPNG;
    },

    handleUploadSuccess(res, file) {
      console.log("handleUploadSuccess--->"+res);
      let data = {
        url: res.data,
      };
      console.log("res.data--->"+res.data);
      this.fileList[this.fileList.length] = data;
      this.$forceUpdate();
      // this.fileList.push(data);
    },

    handleUploadSubmit() {
      console.log(this.fileList);
      let str = '';
      this.fileList.forEach((e) => {
        str = str + e.url + ';';
      });
      console.log(str.slice(0, -1));

      let data = {
        id: this.idEdit,
        carImagesUrl: str.slice(0, -1),
      };
      Request.upload(data).then((res) => {
        if (res && res.code === 200) {
          this.msgSuccess('上传成功');
          this.getTableList();

          //if (this.drawerDetailVisible) this.drawerDetailObj = Object.assign(this.drawerDetailObj, data);
        } else {
          this.msgError('上传失败：' + res.msg);
        }
        this.dialogUploadVisible = false;
      });
    },

    
    //
    handleUploadReport(row) {
      this.titleForReport = '上传评估报告 车牌号：' + row.carLicensePlate;
      this.idEdit = row.id;
      this.form.carEvalReportType = row.carEvalReportType?row.carEvalReportType:1
      this.form.carEvalReportContent = row.carEvalReportContent
      this.dialogUploadReportVisible = true;
    },

     handleUploadBatchReport(row) {
       this.titleForReport = '一键上传';
      this.dialogUploadBatchReportVisible = true;
    },

     handleUploadBatchDelete(row) {
       this.titleForReport = '一键删除(此操作会将输入ID车辆下的图片全部删除)';
      this.dialogBatchReportDeleteVisible = true;
    },

    handleUploadReportSubmit() {
      let data = {
        id: this.idEdit,
        carEvalReportType: 2,
        carEvalReportContent: this.form.carEvalReportContent,
      };
      Request.update(data).then((res) => {
        if (res && res.code === 200) {
          this.msgSuccess('上传成功');
          this.getTableList();

          //if (this.drawerDetailVisible) this.drawerDetailObj = Object.assign(this.drawerDetailObj, data);
        } else {
          this.msgError('上传失败：' + res.msg);
        }
        this.dialogUploadReportVisible = false;
      });
    },

    handleUploadBatchReportDeleteSubmit() {

        let data = {
        startId: this.deleteBatchForm.startId,
        endId: this.deleteBatchForm.endId
      };
      
      Request.uploadBatchDelete(data).then((res) => {
        if (res && res.code === 200) {
          this.msgSuccess('上传成功');
          this.getTableList();

          //if (this.drawerDetailVisible) this.drawerDetailObj = Object.assign(this.drawerDetailObj, data);
        } else {
          this.msgError('上传失败：' + res.msg);
        }
        this.dialogBatchReportDeleteVisible = false;
      });
    },


    handleUploadBatchReportSubmit() {

        let data = {
        startId: this.uploadBatchForm.startId,
        endId: this.uploadBatchForm.endId,
        imageUrl: this.uploadBatchForm.imageUrl,
      };
      
      Request.uploadBatch(data).then((res) => {
        if (res && res.code === 200) {
          this.msgSuccess('上传成功');
          this.getTableList();

          //if (this.drawerDetailVisible) this.drawerDetailObj = Object.assign(this.drawerDetailObj, data);
        } else {
          this.msgError('上传失败：' + res.msg);
        }
        this.dialogUploadBatchReportVisible = false;
      });
    },

    handleReportSuccess(res, file) {
      console.log(res);
      this.form.carEvalReportContent = res.data;
      //this.imageUrl = URL.createObjectURL(file.raw);
    },
    
    handleReportBatchSuccess(res, file) {
      console.log(res);
      this.uploadBatchForm.imageUrl = res.data;
      //this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeReportUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';

      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG 或者 PNG 格式!');
      }
      return isJPG || isPNG;
    },

    // -------------------------------
    handleImport() {
      this.$refs.import.click();
    },

    handleImportSubmit(e) {
      console.log(e);
      const fileTarget = e.target.files[0];
      const formData = new FormData();
      formData.append('excelFile ', fileTarget);
      Request.import(formData).then((res) => {
        if (res.code === '00000' && res.data === true) {
          this.$message.success('导入成功');
          this.getTableList();
        } else {
          this.$message.error('导入失败，请检查');
        }
      });
    },

    handleGo() {
      console.log('123');
    },
  },
};
</script>

<style lang="scss" scope>
.el-card{
    height: 220px;// 这里是固定高度
    width: 220px;
  }
// .form-flex {display:flex;flex-wrap:wrap;justify-content:start;}

::v-deep .el-button + .el-button {
  margin-left: 0 !important;
}

.el-upload-list--picture .el-upload-list__item {
  height: auto;
}
.el-upload-list--picture .el-upload-list__item-thumbnail {
  width: 80px;
  height: 60px;
}
.el-upload-list--picture .el-upload-list__item {
  display: inline-block;
  height: auto;
  width: 120px;
}

.el-upload-list__item {
  transition: none !important;
  -webkit-transition: nonne !important;
}
.el-upload-list__item-name {
  transition: none !important;
  -webkit-transition: nonne !important;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 220px;
}
.el-upload--picture-card {
  width: 220px;
}

.el-dialog__body {
  max-height: 500px;
  overflow-y: auto;
}

.avatar-uploader .el-upload {
  margin-top: 10px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
