import axios from './index';
export default {
  // 获取所有
  listAll: () => {
    return axios.get('/cars/listall');
  },

  // 分页查询
  page: (params) => {
    return axios.get('/cars/listpage', { params });
  },

  // 根据ID获取
  getById: (id) => {
    return axios.get(`/cars/${id}`);
  },

  // 新增
  create: (data) => {
    return axios.post('/cars', data);
  },

  // 编辑
  update: (data) => {
    return axios.put('/cars', data);
  },

  // 删除
  remove: (id) => {
    return axios.delete(`/cars/${id}`);
  },

  // 编辑图片
  upload: (data) => {
    return axios.put('/cars/images', data);
  },

  // 编辑图片
  uploadBatch: (data) => {
    return axios.put('/cars/images/batch', data);
  },

  uploadBatchDelete: (data) => {
    return axios.put('/cars/images/batch/delete', data);
  },

};
